import React from 'react';

export default {
  title: 'Yellow Bee Buzzing'.toUpperCase(),
  author: 'Julie Innis',
  bio: (
    <p>
      Julie Innis’s stories have recently appeared or are forthcoming in
      <i>Pindeldyboz</i>, <i>Fogged Clarity</i>, <i>Up the Staircase</i>, and
      <i>Slush Pile Magazine</i>, among others. In May 2009, she was selected as
      a finalist in Glimmer Train’s Short Story Award Contest for New Writers.
      She lives and works in Brooklyn, New York.
    </p>
  ),
  story: (
    <div>
      <p>
        They call her Blondie or Baby, but to the old woman at the laundromat
        she’s just Bee. ‘Buzz buzz,’ she laughs, as Bee passes her open palm
        through the small window. The old woman drops quarters from her
        fingertips, her skin thin and coppery, like pennies stretched flat on
        railroad tracks. ‘You’re like a yellow bee buzzing.’
      </p>
      <p>
        Most days the old woman’s got a magazine laid flat in front of her, the
        corners thumbed soft and curling; other times she just sits and stares
        out the big window running the length of the laundromat. Not much to
        stare at inside—the laundromat is too small, in use too infrequently to
        hold an eye for long. Four washers sit square in the centre of the room,
        lids up like hungry mouths, and four dryers drop back into the wall
        opposite the cage where the old woman perches, taking dollar bills and
        handing back quarters. When she’s short, Bee empties the trash for the
        old woman, wipes down the table set along the back wall, and checks the
        washers and dryers for stray socks and underwear. Buzz buzz, the old
        woman laughs from her cage.
      </p>
      <p>
        This is not a good neighbourhood and Bee wishes the old woman could
        carry the cage with her wherever she goes, lacing her fingers through
        the chain links, her tiny feet shuffling beneath. Bee has a pen knife
        she’s used a few times, flashing it around when things get a little
        rough. Though when she’s able, she prefers to take a slap or punch. A
        cut lip or black eye is better than to have stabbed someone, she thinks.
      </p>
      <p>
        But Bee doesn’t like to think about these things. Instead, she tries to
        keep her focus on the present, likes how doing laundry cuts her days
        into neat squares. She carries everything she owns in a small bag slung
        over her back—some socks, a pair of jeans, a few cotton tops the colour
        of sherbets. As far back as she can remember, Bee’s been able to taste
        colours. Nights when she wakes up hungry, she holds her shirts up to her
        face, her mouth watering over sweet pinks and tart yellows. She can
        smell colours too: greys like thin curls of smoke, blues like a baby’s
        ear, and greens like cut grass in summer from a place she’s almost
        forgotten. When she does her laundry, she pulls a stool over in front of
        the dryers, and, elbows on knees, chin cupped in hands, she watches the
        colours rotate and tumble, imagining the round glass doors are like
        spyholes cut into dreams.
      </p>
      <p>
        Here’s the secret Bee hopes the old woman in the cage never knows — Bee
        has taken some things from the laundromat. A sock stuck to the side of a
        dryer, a child’s wet t-shirt balled like a fist, a pair of shiny gym
        shorts slid down to the floor, little things she hopes people won’t
        miss. These things she carries in her bag also, folded neatly and pushed
        to one side.
      </p>
      <p>
        Outside it’s getting colder and Bee shivers when the old woman’s fingers
        brush against her palm. The only other person in the laundromat is a
        young woman, her brown hair pulled back into a short ponytail. Bee has
        seen this girl before, on the next block over where people are moving in
        with fancy cars they park along the street and in the alleys behind the
        rowhouses. At night they pour out of their buildings, calling to each
        other from their stoops as they make their way to a bar, The Yacht Club,
        just a block away—a bar with a neon jukebox and pool tables, peanuts in
        baskets and cheap beer in pitchers, waitresses and bartenders in shorts
        and polo tops. Bee’s been to this bar a few times. When she first came
        to the neighbourhood, just turned fifteen, old men sat rooted to the
        barstools and the only music came from a transistor radio the bartender
        kept next to the cash register. It was called The Basin then.
      </p>
      <p>
        Bee takes a washing machine across from the girl. The girl shakes out
        each item from her laundry bag before dropping it into the washer. A
        blouse with thin blue stripes, a pair of grey pants, a white turtleneck.
        She holds up a bra, bright yellow like a lemon drop, the straps laced
        between her fingers. The girl contemplates the bra, examining the
        scalloped-edged cups before setting it aside. Clearly it should only be
        washed by hand, Bee thinks, and she imagines filling a sink with warm
        soapy water, squeezing the suds through the silky fabric and the soft
        padding that holds the cups’ shape. It is the prettiest thing Bee has
        ever seen.
      </p>
      <p>
        After the girl finishes loading her machine, she scoops the bra back
        into her laundry bag and settles down on a stool with a book. Bee turns
        back to her own bag with a sigh and dumps the contents into the machine,
        her clothes now seeming as shabby as the day. There’s no laundry to
        watch turning in the dryers, so instead, Bee stands at the window,
        staring out across the street, the light behind casting her reflection
        onto the glass which she works hard to stare straight through. A man she
        knows comes out from the old hotel across the street. Bee sleeps there
        some nights, in a small room with a narrow bed she makes each
        morning—unmade beds scare her, especially if it’s dark when she comes
        in, the sheets twisted tight like bodies where they shouldn’t be. She
        turns from the window, but the man has already seen her and crosses the
        street.
      </p>
      <p>
        He tells her he’s taking her to lunch. Bee says she’s not hungry, that
        she’s got things in the wash. They’ll wait, he says.
      </p>
      <p>
        The old woman listens in, her head up now from her magazine. She smiles
        and nods at Bee. Bee thinks her smile must be approval; after all the
        man is not unattractive, standing a few inches taller than Bee, and his
        hair, long to his shoulders, is clean, wavy and black. His eyes also
        look black, though Bee knows they’re actually dark brown with small grey
        rings at the centre. The man likes to wear dark colours and stands
        straight as a smokestack. When he smiles, he shows all of his teeth. He
        smiles now at Bee as she pulls the strap of her bag across her shoulders
        and follows him.
      </p>
      <p>
        They walk to a lunch counter a few doors down. After they’ve ordered
        burgers and fries and Bee’s got her straw unwrapped and into her soda,
        the man says, I have a favour to ask.
      </p>
      <p>
        Bee swallows the soda she has in her mouth, careful to look at
        everything but the man. She looks at the boy with his back towards them,
        scraping thick lines of grease from the grill with a metal spatula. She
        looks down at the counter, pushing the tip of her index finger into a
        cigarette burn mark. She chews the inside of her lip, but before she can
        think of an excuse, the man slides his arm around her shoulders,
        squeezing her into his side so tight she feels the edges of her ribs
        press together.
      </p>
      <p>
        Be a good girl, he says. She shrugs her shoulders and rights herself on
        the stool. She’s not so hungry anymore for the basket of food the boy
        sets down in front of her, the wax paper shiny with grease. She picks at
        her fries.
      </p>
      <p>All it is, he says, is a simple phone call.</p>
      <p>
        Bee knows that nothing is ever that simple. She waits. The man pushes a
        quarter at her, nodding his head toward the phone on the wall behind
        them. Phone’s right there, he says, what could be easier?
      </p>
      <p>
        Bee takes the quarter from the counter, turns it in her fingers and
        looks at the man. She sees tiny squares of light bouncing back at her in
        his eyes. He leans in toward her, brushing his fingers, gritty with
        salt, against her face. She tastes ketchup on his tongue.
      </p>
      <p>
        Before she makes the call, he tells her what to say. There’s a guy
        missing some things. He’s going to want them back. Tell him to meet you.
        Tell him you found his things.
      </p>
      <p>
        Bee knows the guy must be some tourist, knows exactly how things
        disappear from the cars with out-of-state plates that tourists park on
        the streets downtown, trying to avoid the high-priced garages closer to
        the Harbor and its attractions. She knows because she’s made these calls
        before. But there’s no use avoiding it. The man will just keep coming
        back until she does what he tells her to do.
      </p>
      <p>
        She dials the number and tries to breathe slowly through her mouth so
        her voice will sound soft and pretty when the tourist answers. Bee tells
        him that she found his bag while jogging this morning. As she’s
        explaining, she begins to believe this other life, her running shoes
        laced tight, her hair pulled up in a baseball cap, a friend, like the
        girl at the laundromat, running with her.
      </p>
      <p>
        The man snaps his fingers in front of her face. Concentrate, he says.
      </p>
      <p>
        Through the phone Bee can hear the tourist swallow. He sounds nervous as
        he asks if she can bring the bag to him. He’s at a hotel out by the
        airport. She explains that she has no car. The tourist says he’ll pay
        for a cab.
      </p>
      <p>
        Bee thinks her way around this, her lips pursed tight before she says,
        I’d feel much safer if you met me in my neighbourhood.
      </p>
      <p>
        He hesitates then agrees to meet her at six. She gives him the address.
      </p>
      <p>
        After she hangs up, the man tells her he’ll be by to get her later. So
        we can do this, he says.
      </p>
      <p>
        The girl with the ponytail is sitting across from the dryers when she
        returns. Bee doesn’t like to leave her things in the washer this long,
        doesn’t like how cold wet laundry gets, her fingers stiff and numb as
        she pries her clothes away from the sides of the drum. She pulls up a
        stool next to the girl. Towels take so long to dry, don’t they, Bee
        says.
      </p>
      <p>
        The girl glances towards Bee with her eyes, her eyebrows raising
        slightly, keeping her face down in her book. She shifts in her seat.
      </p>
      <p>
        A quarter really only gives you about eight minutes in these dryers, Bee
        says.
      </p>
      <p>The girl makes a sound in her throat like a hum or a swallow.</p>
      <p>
        Cotton things I like to keep a little damp so they don’t shrink, that’s
        about one quarter, Bee says.
      </p>
      <p>I’m just trying to read here, the girl says, her face pulled tight.</p>
      <p>
        Sorry, Bee says. She studies the book the girl’s holding. Looks good,
        she says. The girl nods then turns away.
      </p>
      <p>
        Bee’s clothes dry quickly and she carries them to the table where the
        girl’s laundry bag lies, a stack of neatly folded things next to it. She
        pushes her hand flat up into the bag and finds what she’s looking for.
        She stops, glances around. No one is watching. She pulls her hand from
        the bag, shoving the yellow bra deep into her pants pocket.
      </p>
      <p>Bye bye, the old woman says as Bee is leaving.</p>
      <p className="divider">* * *</p>
      <p>
        The man comes for her, rapping lightly on her door. Bee’s been waiting
        for hours, worried that the girl will complain to the old woman, that
        she will not be allowed to return to the laundromat. If that happens,
        she does not know what she will do.
      </p>
      <p>
        Get a move on, the man says. They walk, his hand on her elbow, to an
        alley just past the address she gave the tourist. The alley is dark, lit
        only in patches from the apartment windows above. A rat runs out from
        under the dumpster. This city’s filthy, the man says, jumping back. But
        Bee stays crouched down. When the car pulls up to the curb, the man will
        tell her what to do. For now, she just waits, her hand sifting through
        her bag, the colours of the clothes she’s taken running through her
        fingers, reds and blues and purples and greens and now yellow. She can
        hear it. Yellow fills the air around her. Yellow like a thousand bees
        buzzing.
      </p>
    </div>
  ),
};
