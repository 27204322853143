import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom';

export const Bio: React.FunctionComponent<{ bio: React.ReactElement }> = ({
  bio,
}) => <div className="bio">{bio}</div>;

export const Navigation: React.FunctionComponent<{
  prev: string;
  next: string;
}> = ({ prev, next }) => (
  <div className="navigation ringside">
    <Link to={`/${prev}`} className={prev === '' ? 'disabled' : ''}>
      Prev
    </Link>
    <Link to={'/'}>Home</Link>
    <Link to={`/${next}`} className={next === '' ? 'disabled' : ''}>
      Next
    </Link>
  </div>
);
