import React from 'react';

export default {
  title: 'Some Forgotten Colours'.toUpperCase(),
  author: 'Kirk A.C. Marshall',
  bio: (
    <p>
      Kirk Marshall is the Brisbane-born(e), Melbourne-based author of{' '}
      <i>A Solution to Economic Depression in Little Tokyo, 1953</i>, a 2007
      Aurealis Award-nominated full-colour illustrated graphic novelette. He
      holds a Bachelor of Creative Industries (Creative Writing), with
      Distinction from the Queensland University of Technology, and a
      first-class Honours degree in Professional Writing from Deakin University.
      He has written for more than forty publications, both in Australia and
      overseas, including <i>Going Down Swinging</i>, <i>Voiceworks</i>,{' '}
      <i>Word Riot</i> (<span className="sc">USA</span>) and{' '}
      <i>Through the Clock’s Workings</i> (published by Sydney University
      Press). In 2009, he edits <i>Red Leaves</i>, Australia’s first (and only)
      English-language / Japanese bi-lingual literary journal.
    </p>
  ),
  story: (
    <div>
      <p>
        There’s a loaded literary playfulness in introducing a story of youthful
        self-actualisation with a singular, pedigree memory. For Fisher Price,
        it was an elegantly tragic moment that claimed control of his life, this
        red glorious summer: today, staggering into his studio apartment with
        staccato footfalls, to witness his girlfriend of nineteen months having
        sex with someone else. It came as all mortal wounds do, fist-fast and
        savage, and left its slow-burning chrysanthemum image in the wretched
        and baleful centre of his mind, there to play on continual loop, like a
        pop song that loses its flavour while you listen to it. Moments like
        these are akin to extraterrestrial infection, a microscopic war being
        battled out for the sovereignty of your sanity, so that you can only
        stand silent, and watch for one minute—two—before the pulpy churned
        contents of your stomach flames up your throat and out. Fisher clutched
        his vomit-soaked sternum with the fluttering palms of his hands,
        nauseous with strange feelings of drowning and detachment, and then
        exited with all the cultivated politeness he could muster, shutting the
        door and turning the sign over as he clutched its familiar brass knob.
        The sign now read: <span className="sc">please do not disturb</span>.
        For Fisher, it was a belated sentiment.
      </p>
      <p>
        Occasionally, while contributing to the greater cosmic farce,{' '}
        <i>other</i> moments will fall into place, too, sweet and sweet-burning
        moments, and cliches will become the vocabulary for adventure again, and
        photographs will be captured without there ever being a camera. And
        these, too, are just as real, and just as likely. Still, Fisher felt
        himself become old and distorted with unfathomable speed, tasting tears
        that bled from the woolly corners of his eyes, each one being expelled
        from his pores as though fat and lethargic maggots, warping the sunset
        before him into ribbons of sideshow colour. He blinked away tears,
        shackled horrific sobs, glared hard at the melancholy firework of sun
        and sky, and listened to the black glass of the ocean seethe and seduce.
        It was mid-morning and a cigarette trembled between intrepid teeth.
        Fisher studied the intricate patterning of his palms, his body hunched
        low, heart blue like cooling agate. He felt boring, uninspired, a plane
        banking sharply into hard ground, unapologetic apocalypse, a nobody boy
        with nobody’s joy. He wanted to die, and he wanted this to be
        spectacular and new, such a profound revelation, an existential epiphany
        that would mark him off as an individual, fill him with a sense of
        value, make him out to be the channel through which unimagined darkness
        flows and cascades. But <i>no!</i>—everyone has wanted to die, most
        everyone has lost in love, and half these people have had their partner
        betray the serenity and integrity of the heart somehow, by fucking the
        takeaway boy on the living room wine-rack with delicious abandon. Yes.{' '}
        <i>He</i> was but a moment, himself, a particle of singularity and of
        insignificant design, just forgotten sand lost in the jagged sweep of
        wind cleaving over the sugarwhite coast. No-one would remember Fisher
        Price. No-one would remember the sorrow that brooded in the nicotine of
        his breath.
      </p>
      <p>
        Other moments do fall into place, too, though. <i>Occasionally.</i>
      </p>
      <p>
        A girl interrupted his reverie and the suicidal smoke kissing his
        hunched form. He ground out his ciggy. She sat beside him, smelling like
        the best scented thing you could ever waste a prayer upon, and smiled. A
        feeling so hot and alive cut through his weepy chest, and he sat reeling
        from the thunderstorm resurrection of his old and distorted heart. His
        insides were alive with new blood, strawberries, and soft giggles. Oh,
        adore: Fisher Price had been dumped and had fallen for someone else
        entirely within the space of a single cigarette.
      </p>
      <p>‘Hi,’ she said.</p>
      <p>‘Talk to me,’ she said.</p>
      <p className="divider">* * *</p>
      <p>
        Fisher, svelte and lithe and evasive as an untamed swallow, avoided
        immediate thigh-to-thigh contact with the girl, his heroine, by
        wriggling to the furthermost end of the bench. He immediately
        contemplated the knees beneath his sickly faded jeans, purple and taut
        like choke, and maintained his holy vigil, for fear of dropping the
        sabre of sobriety, for fear he might serenade this lovely lady with
        unsubtle thoughts: that she was bewitching, that she was his favourite
        new person, that she burned the air with angel perfumery. He puzzled
        over the spidery system of veins and nerves marking the fabric of his
        Levis, a tapestry of tiny thunderstorms, and then looked up—further
        reverence would corrupt their relationship. He wanted to be her guy.
        Yes. He would prove to her his worth.
      </p>
      <p>
        ‘My name’s Kate,’ said Kate, inching closer and exchanging knowing
        glances with Fisher through the impenetrable and unruly canopy of a
        platinum blonde fringe. He turned his head, espying the fleeting
        haunches of a sensual jungle cat through this network of fronds. She
        blew an updraft plume of oxygen with her scarlet lower lip, red as the
        minuscule corona of blood in the milk-white of a fingernail, and the
        tiny gust disrupted the prolonged effect of there existing a heart of
        sullen darkness enshrouding her eyes; her fringe rose and rearranged
        itself in the attention provided by that distortion of upward air, and
        Fisher was decreed the dignity of meeting the blaze of her gaze, two
        eyes as sharp and green as hemispheres enthralled by the paranormal glow
        of St. Elmo’s fire. He felt a hand, small and sunny, foisted into his
        own, and it squeezed the taut flesh clustered about the joints of his
        knuckles with a fury that suggested nothing alternative to tremulous and
        triumphant orgasm. ‘My name’s Kate, boy, and I’m fifteen.’
      </p>
      <p>
        Fisher spun away; here he was, broncobuster and laureate, breaker of
        hearts and hymens, awash and ensnared in a personal hurricane of idiot
        confusion, a hack writer come unstuck in time, a dead metaphor man, a
        gangling monstrosity of twenty-three, composed of the limb and ligament
        of a daddy longlegs, feeling a single revelation before him, like a
        proud bison: Fisher was fourteen again.
      </p>
      <p>
        ‘Hey Kate,’ he grinned, tears curling like precious stalactites from the
        curvature of his quivering eyelashes, ‘I’m Fisher. I’m an old fart who
        should be wearing a hounds-tooth tracksuit.’
      </p>
      <p>
        She liked that. She laughed and stars that couldn’t be seen wheeled and
        somersaulted. Fisher realised he hadn’t released her hand. He did so.
        Kate surveyed him critically, with a tongue juxtaposed between her
        butchering smirk. ‘You look like you forgot some colours, boy with the
        half-name of a fridge. I want to teach you.’
      </p>
      <p>
        The arsenal of gloom and the inventory of sarcasm buckled open, creaking
        wide within the apple of his abdomen, and Fisher narrowed his eye, and
        so too his acceptance. ‘Teach me?’ He blanched minutely beneath the beam
        of the bleary sun. ‘Kate, see, you don’t understand—’
      </p>
      <p>
        ‘Stop being old like the arsehole in your future mirror, and take one
        second, one second, boy, to see that you’re dwelling in smoke when there
        exists so much summer. It’s <i>palpable</i>, yeah? That’s the right
        word, yeah?’
      </p>
      <p>
        Fisher felt her hand, smooth as a river stone and without discomfort,
        slide back into his.
      </p>
      <p>‘I was only going to ask: what do you wish to teach me?’</p>
      <p>
        She stood up, her body a particle explosion of unapologetic originality.
        ‘This is a wetsuit, boy fish.’ Kate laughed, laughed without a scintilla
        of reservation at Fisher Price with her arched back, and through her
        swimmy stare. ‘I can teach you the thing that is called surfing,
        doofus.’
      </p>
      <p>He found levity, then, this boy with the half-name of a fridge.</p>
    </div>
  ),
};
