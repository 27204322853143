import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { Switch, Route, Link } from 'react-router-dom';

import { Bio, Navigation } from './story';
import fetchStory from './fetchStory';

const credit = () => (
  <i className="credit">
    Designed & edited by{' '}
    <a href="mailto:contact+slw@rohanbassett.com" className="email">
      Rohan Bassett
    </a>
  </i>
);

const useNavigate: Function = (title: string) => {
  useEffect(() => {
    document.title = title;
    window.scrollTo(0, 0);
  });
};

const RenderIndex = () => {
  useNavigate('7LW');

  return (
    <div className="App">
      <div className="top index">
        <div className="mast">
          <div className="masthead ringside">
            <Link to="/" className="hover">
              seven letter words
            </Link>
          </div>
        </div>
        <div>
          <div className="links ringside">
            <div className="link">
              <Link to="/chiles">
                de•con•struc•tion <span className="tc">Ted&nbsp;Chiles</span>
              </Link>
            </div>
            <div className="link">
              <Link to="/kertesz">
                Endless Summer <span className="tc">Andrew&nbsp;Kertesz</span>
              </Link>
            </div>
            <div className="link">
              <Link to="/innis">
                Yellow Bee Buzzing <span className="tc">Julie&nbsp;Innis</span>
              </Link>
            </div>
            <div className="link">
              <Link to="/marshall">
                Some Forgotten Colours{' '}
                <span className="tc">Kirk&nbsp;A.C.&nbsp;Marshall</span>
              </Link>
            </div>
            <div className="link">
              <Link to="/ewing">
                Four Dreams About A Boy I Thought I’d Forgotten{' '}
                <span className="tc">Virginia&nbsp;Ewing</span>
              </Link>
            </div>
            <div className="link">
              <Link to="/edwards">
                The Bookmaker’s Son{' '}
                <span className="tc">Mark&nbsp;Edwards</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom centre">
        <Bio bio={credit()} />
      </div>
    </div>
  );
};

export const Main = () => (
  <Switch>
    <Route exact path="/" component={RenderIndex} />
    <Route
      exact
      path="/chiles"
      render={({ match }) => (
        <RenderStory path={match.path} prev="" next="kertesz" />
      )}
    />
    <Route
      exact
      path="/kertesz"
      render={({ match }) => (
        <RenderStory path={match.path} prev="chiles" next="innis" />
      )}
    />
    <Route
      exact
      path="/innis"
      render={({ match }) => (
        <RenderStory path={match.path} prev="kertesz" next="marshall" />
      )}
    />
    <Route
      exact
      path="/marshall"
      render={({ match }) => (
        <RenderStory path={match.path} prev="innis" next="ewing" />
      )}
    />
    <Route
      exact
      path="/ewing"
      render={({ match }) => (
        <RenderStory path={match.path} prev="marshall" next="edwards" />
      )}
    />
    <Route
      exact
      path="/edwards"
      render={({ match }) => (
        <RenderStory path={match.path} prev="ewing" next="" />
      )}
    />
  </Switch>
);

interface Props {
  next: string;
  path: string;
  prev: string;
}

const RenderStory: React.FunctionComponent<Props> = ({ next, path, prev }) => {
  const { title, author, story, bio } = fetchStory(path);

  useNavigate(`7LW • ${title} by ${author}`);

  return (
    <div className="App">
      <div className="top">
        <div className="mast">
          <div className="masthead ringside">
            <Link to="/" className="hover">
              seven letter words
            </Link>
          </div>
        </div>
        <div>
          <div className="title ringside">{title}</div>
          <div className="byline ringside">{author}</div>
          <div className="content">{story}</div>
        </div>
      </div>
      <div className="bottom">
        <Bio bio={bio} />
        <Navigation prev={prev} next={next} />
      </div>
    </div>
  );
};
