import React from 'react';

export default {
  title: 'de•con•struc•tion',
  author: 'Ted Chiles',
  bio: (
    <p>
      Ted Chiles’ stories have appeared or are forthcoming in several literary
      journals including <i>Vestal Review</i>, <i>The Anemone Sidecar</i>,{' '}
      <i>Permafrost</i>, <i>The Abacot Journal</i>, <i>Prism Review</i> and{' '}
      <i>The Binnacle</i>. Four of his stories were adapted and performed at the
      New Short Fiction Series in Los Angeles in September 2007. His story
      ‘Knife’ won the 2008 Midnight Sun Fiction Contest at Permafrost. Living in
      Santa Barbara, CA with a poet and three cats, Chiles teaches Economics,
      the most dramatic of the Social Sciences.
    </p>
  ),
  story: (
    <div>
      <p>
        It was going to be our third date and things had stalled. Last time, I
        got the short kiss. My dad once told me not everyone was going to like
        me, and I know he’s right, but I didn’t want to lose this one. Honestly,
        she’s top ranked. Saturday night at a four star restaurant. So I pulled
        out the big guns: I decided to cook for her. The problem was that we
        hadn’t progressed that far on the date sequence. We’d done coffee and
        then lunch. Our third date was going to be a movie and then a drink or
        two. Dinner at my place was two, maybe three steps away, and as things
        were going, it would just be me with some cheese toasties.
      </p>
      <p>
        She let me choose the movie, which was probably a test of some kind. I
        picked a drama somewhere between a chick flick and mystery. Didn’t want
        to appear to be trying too hard, or forcing the issue with a high body
        count. The movie got out later than most of the others, and that was the
        real criterion.
      </p>
      <p>
        I offered to buy her some popcorn and she asked if I was getting
        anything. I told her I wasn’t, and offered to buy her a drink. Movies
        aren’t really movies without popcorn, but if I had bought some she would
        have dug into mine. I needed her hungry.
      </p>
      <p>
        After the film we went to a bar halfway between the cinemaplex and my
        apartment. She told me she just had an article accepted for publication.
        I ordered us some champagne to celebrate. Okay, it was a California
        sparkling wine. A Blanc de Blanc, to be exact—well known for stimulating
        appetite. I picked the bar because I knew the kitchen closed at nine and
        the movie didn’t get out until nine thirty. It worked. She asked if she
        could see a menu and the waitress told her the bad news.
      </p>
      <p>‘I could cook for us,’ I said.</p>
      <p>
        ‘You cook?’ she asked. This wasn’t really a question. It sounded more
        like a statement of disbelief.
      </p>
      <p>
        ‘I’m not a chef, but I make a good pizza or a nice steak,’ I said. ‘I
        make a great sandwich.’
      </p>
      <p>
        I don’t think she accepted my offer out of hunger, but the possibility
        of having her expectations confirmed enticed her. Pizzas, steaks, and
        sandwiches are guy-food. She probably anticipated some deli monster: a
        half-pound of corn beef, quarter pound of swiss, and mustard on rye.
      </p>
      <p>
        At the door, I turned and said, ‘I wasn’t expecting company.’ We walked
        in and she saw the paper on the floor and a wine glass and plate on the
        coffee table. Through the cracked bedroom door, my neatly made bed had
        my work clothes scattered about it. The bathroom and the kitchen were
        spotless. I tidied away some journals and poured us some red wine, a
        Malbec that I’d left breathing since that afternoon.
      </p>
      <p>‘Why don’t you put on some music,’ I said.</p>
      <p>
        While she looked through my albums and CDs, I turned the oven to 350
        degrees and opened the fridge. I’d bought everything I needed that
        morning and opened all the ingredients. I pulled them out: prosciutto in
        butcher paper, fresh mozzarella in brine already a third gone, a jar of
        homemade basil pesto with walnuts instead of pine nuts, arugula, and a
        couple plum tomatoes. A loaf of peasant bread sat on the counter.
      </p>
      <p>‘Allergic to anything?’</p>
      <p>‘Nope.’</p>
      <p>‘Do you eat ham?’ I asked.</p>
      <p>‘In all forms,’ she said.</p>
      <p>I liked that answer.</p>
      <p>
        The bread was fresh; even so, I popped it in the oven to crisp the
        crust. I sliced the tomatoes and sprinkled each slice with a little
        ground sea salt. She came over and sat on one of the stools at the
        kitchen bar. I cut four thick rounds of mozzarella and put the rest back
        in the fridge. Took a sip of wine. She watched me, smiling. I pulled the
        bread from the oven and put it on the old wood cutting board. Slicing a
        third of the loaf, I turned that piece on its side and sliced through
        it. I built the sandwich from the bottom up: a thin cover of the pesto,
        followed by slices of prosciutto, fresh mozzarella and tomatoes. I
        paused, looked at the sandwich, and reached for some balsamic vinegar. I
        drizzled a little over the tomatoes and followed with some extra virgin
        olive oil.
      </p>
      <p>
        I didn’t taste any of the ingredients, nor did I offer her a taste. I
        wanted to tell her about the sandwich. How the symmetry of colours,
        white to green to red, descended on the centre. How all the tastes were
        involved, from the salty pesto to the bitter arugula, from the savoury
        prosciutto to the sweet and sour of balsamic on tomato. How the buttery
        avocado mirrored the creamy mozzarella. But I kept the lecture to
        myself. The sandwich was going to taste great.
      </p>
      <p>
        I reached into the fridge and came out with the unexpected avocado. I
        removed the plastic wrap, embedded the knife into the seed, lifted the
        avocado off the cutting board and gently grasped and rotated, extracting
        the pit. I pushed the seed off the blade into the sink and then
        separated the green flesh from the dark skin, sliced and covered the
        tomatoes with the avocado. I topped everything with a mound of arugula
        lightly dressed in vinegar and oil, and cut the sandwich in half.
      </p>
      <p>
        Collecting a bottle of sparkling water from the refrigerator, I gathered
        up a couple of glasses.
      </p>
      <p>‘Could you carry these?’ I asked.</p>
      <p>
        She took them from me and traced her finger along mine, just a little.
      </p>
      <p>
        Putting a couple of cotton napkins on the cutting board, I carried
        everything into the living room and set the food on the round coffee
        table.
      </p>
      <p>
        We sat on the carpet with the sandwich between us. She reached out and
        pulled her half of the sandwich closer. I did the same and waited for
        her to take the first bite. Instead, she picked out a leaf of arugula
        and ate it. Then another, and another. She ate the leaves like they were
        potato chips. The same organic arugula I’d bought at the farmers’ market
        at seven fifteen that morning.
      </p>
      <p>
        I tried to figure out how to get her to take a real bite when she asked
        the question.
      </p>
      <p>‘Do you have any mayonnaise?’</p>
      <p>
        I froze. It was like time stopped. I didn’t understand. Mayonnaise.
        She’d asked for mayonnaise? I wanted to lie. I could have told her I had
        an allergy, but she might have searched the refrigerator.
      </p>
      <p>‘Sure,’ I said.</p>
      <p>
        I opened the fridge and bent over, pretending to look for the
        mayonnaise. Stalling. I felt her hand on the back of my neck. Her
        fingers were just touching me; letting me know she was there. I turned
        to her with the jar in my hand. I hoped she was going to kiss me, and if
        she had I could have dropped the damn jar and gotten away with it. But
        she didn’t kiss me. She bent down, scanned the shelves, then reached in
        and pulled out a jar of pickled jalapenos.
      </p>
      <p>
        <i>Jalapenos</i>? Was it the avocados? But with prosciutto? And fresh
        mozzarella?
      </p>
      <p>
        I got my kiss—a light one on the cheek. She walked back to the coffee
        table, sat down, and opened the jar. I expected her to eat a pepper, at
        worst put a couple on the sandwich. She took off the top slice of bread
        and pulled off the avocado, which was now on top since she’d eaten all
        the arugula. She ate the tomatoes. The avocado lay next to the ruined
        sandwich. I thought, maybe she doesn’t like avocados.
      </p>
      <p>
        Then she poured the juice from the jalapenos on the bread, slapped some
        mayo on top of that, and put the avocado back on, closing the whole
        thing.
      </p>
      <p>She took a bite.</p>
      <p>It felt like she’d painted a moustache on a Picasso nude.</p>
      <p>
        That’s when her foot landed softly on my thigh, her toes tickling me as
        they moved forward.
      </p>
    </div>
  ),
};
