import React from 'react';

export default {
  title: 'Four Dreams About A Boy I Thought I’d Forgotten'.toUpperCase(),
  author: 'Virginia Ewing',
  bio: (
    <p>
      Virginia is an emerging writer from Melbourne. She recently completed her
      honours degree at the University of Melbourne’s School of Creative Arts.
      Her fiction and poetry have appeared in journals such as <i>Vivid</i> and{' '}
      <i>SWAMP Writing</i>.
    </p>
  ),
  story: (
    <div>
      <div className="section">One</div>
      <p>
        I’m at some kind of school camp: an old commercial kitchen, a dining
        hall, bunk beds. I go looking for him. I walk onto the rooftop and it’s
        covered in snow and ice, surrounded by sea—icebergs everywhere. At the
        edge of the roof a man who is sort of my father, but isn’t, tends to
        penguins as they play, sliding down ramps of snow on their bellies. In
        the distance, galleons battle, flying flags the size of tennis courts. I
        go back downstairs; the sound of penguin chatter and distant cannons
        mutes as the door closes behind me. Down another level there is a
        second-hand store. The woman in there burns too much incense and I have
        to leave, breathless. I stumble into in a hall, set up as an impromptu
        church. I sit beside him on the end of a pew. The congregation approach
        the altar for communion, genuflect, open mouthed. He and I stay as they
        move away from us like a receding wave. We hold each other’s hands in
        the void between our laps. Our foreheads fall together. He says in his
        earnest way that’s almost rehearsed,{' '}
        <i>I’ve missed you so fucking much</i>. We kiss, I feel whole, that’s
        all I remember.
      </p>
      <div className="section">Two</div>
      <p>
        I’m at some kind of lunch for my father—his birthday maybe. I don’t want
        to be there. It’s in a house in Brunswick—it’s big and old. My nieces
        and nephews are smoking dope and playing Xbox in a converted garage. I
        slide open the glass door and leave. I walk down the side of the
        property and push aside a sheet pegged to a line that is replacing a
        wall; in my dreams, buildings are often ruins. On the other side of the
        sheet, it’s his house. I walk down that corridor that I have stumbled
        drunkenly down so many times. The first door is his; it is missing too,
        crumbled away. I am invisible. I am a ghost. I watch a brown haired girl
        I’ve never seen get up to leave his bed—to get dressed. He grabs her
        arms and smiles—he doesn’t want to let her go. She slips away past me
        (through me?) to the old bathroom with the hole in the wall where the
        ivy pokes through. He keeps smiling, knowing she will return. I try to
        make him hear me. See me. Feel me. I try to kiss him. I scream myself
        breathless, unheard at the foot of his bed. I am weak, trembling,
        weeping. I grab the edge of his mattress and shake it and suddenly I am
        real. He cries out quietly in shock. I turn and leave. He follows me
        down that broken, drunken hallway, trying to grab at my hands as I pull
        away. I want to turn and kiss him but my feet keep walking. He calls my
        name again and again. I push past the sheet that stands in for his front
        door and burst onto Sydney Road; bright; crowded. Sunblinded, I stop. He
        takes my hands and turns me to him. <i>Hey</i>,—he says.
      </p>
      <div className="section">Three</div>
      <p>
        I start a campaign of industrial sabotage against Target because their
        bras never fit me right. It involves throwing homeless people to their
        deaths from the top of high-rise tenements after force-feeding them
        Target brand confectionery so the autopsies will suggest it induces
        psychosis. In one of the struggles, I slip from the roof. Suddenly I am
        sitting on the gutter beneath the building with my knees up to my chest.
        I’m wearing that old white jumper—my favourite one from fifteen years
        ago. My hair is long and loose. I’m crying. He comes up and puts his arm
        around my shoulders. I’m freezing. I tell him I’m freezing. He tells me
        that’s because I’m dead. I don’t want to believe him. He squeezes me
        tighter and says <i>Well lady, what did you expect?</i> I can’t stop
        crying. He keeps holding me and I don’t get warmer. I realise he’s
        right; I instantly cease to exist.
      </p>
      <div className="section">Four</div>
      <p>
        It’s late at night and I’m waiting for him to come off stage. I’m
        drinking alone in a bar—champagne from a jam jar with a bendy straw.
        Everything is sort of golden; I think it’s the fifties. I recline on a
        chaise lounge, an empty brass birdcage hanging over my head. My feet are
        bare. I am wearing a dress, pale blue, crushed silk, full skirt. When he
        comes up to me, I see it matches his tie. He pins an orchid to the
        bodice of my dress. We leave together. His friends watch us, wondering
        who I am. Their eyes make me bristle; I feel inscrutable and fearless.
        It is a warm summery night, and we walk through lush green gardens near
        a freeway. I grab his hand and lean into him, and it’s just like that
        night—our first night together. We share the jam jar champagne as we
        walk and begin to kiss. He asks if I am sure I want this. I say I know I
        shouldn’t; I know I’ll pay for it; but I want it more than anything. The
        streets are almost empty and everything echoes. I squeeze his hand
        tighter, afraid to look at him in case he disappears. We dash across the
        freeway and are nearly hit by a cement truck. He slows but I pull him
        on. We get to the grass on the other side and collapse under a palm
        tree, lit by a pale blue flood light. He is so warm. The night air on my
        bare shoulders feels amazing. He tells me he loves me—completely casual,
        like it’s nothing. I am too bewildered to say it back. I just kiss him.
        Everything else is a blur.
      </p>
    </div>
  ),
};
