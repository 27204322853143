import React, { Suspense } from 'react'; // eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

require('./App.css');

import { Main } from './App';

console.log('💃We are family ... 💃');

const Root = (): React.ReactElement => (
  <Suspense fallback={() => null}>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </Suspense>
);

const RootElement = document.getElementById('root');

if (RootElement) {
  ReactDOM.render(<Root />, document.getElementById('root'));
}
console.log('💃💃💃 ... I got all my sisters with me 💃💃💃💃💃');
