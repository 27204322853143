import React from 'react';

export default {
  title: 'Endless Summer'.toUpperCase(),
  author: 'Andrew Kertesz',
  bio: (
    <p>
      Andrew Kertesz is a writer from Tasmania, Australia. His work has
      previously appeared in <i>Voiceworks</i> and <i>The Lifted Brow</i>.
    </p>
  ),
  story: (
    <div>
      <p>
        I’ve been hidden in here for a long time. I’m behind this woman. See me
        now? She’s a girl you loved once, isn’t she? She’s very beautiful. I
        like her long fingers. They’re graceful. Of course, for all I know,
        she’s long gone. I don’t have the luxury of seeing the world as it
        really is. Everything I know is second-hand; extrapolated from your
        memorial artefacts. So, I’m sorry if seeing her face again is
        uncomfortable for you. I had no way of knowing.
      </p>
      <p>
        I should introduce myself: I’m a dirty little lodger, a squatter in your
        soul. You probably picked me up from a toilet-seat or somewhere equally
        unsanitary. As a larva I might have crawled up your nostril while you
        slept, or leapt into your open mouth from a drop of sneeze. My species
        isn’t common, but we’re blessed with the resourceful lack of decorum
        that’s common to all parasites. It’s the key to our modest successes.
      </p>
      <p>
        This is my home; some place you’ve been, and now remember in imperfect
        detail. The sky is a little too green, the scenery fuzzy with manifest
        disinterest, and the people are as lively as front-window dummies, but
        I’m very fond of the place. There’s a pleasant feel to it. My special
        orifices register it as a smell. I’d almost liken it to cinnamon, or
        roasted coffee, but I despise that kind of wine-label pomposity. I’ll
        just call it ‘nice’ — we can leave it at that.
      </p>
      <p>
        This memory’s my gall; my pearl. I’ve made it my own — it’s very
        comfortable. The psychic walls are soft and warm, and it’s always a
        summer night in here. All my adult life I’ve sat opposite your girl
        here, and eaten the dinner that’s perpetually on this table-top. And you
        know what? I’m yet to get bored.
      </p>
      <p>
        You might think a maggot with rippling white belly-bits and hairy
        mouthparts wouldn’t recognise love when he sees it, but I do. I
        appreciate it like a gourmand. I feed on the stuff. I sit in the chair
        you once sat in, look her in the eye as you did, and lap up the
        sentiment with specialised glands.
      </p>
      <p>
        Don’t take it personally. I’m not out to get you. This isn’t a
        vindictive game, it’s all a matter of survival. We’re both level-headed
        creatures, and I’m sure you can recognise pragmatic consumption when you
        see it.
      </p>
      <p>
        So what if I chow down on your paralysed emotions? Is it wrong of me to
        appreciate the slip of phosphorescence along the blade of her jaw; the
        tiny hairs behind her ears, dark with warmth; the sound of her voice;
        her smile, so soft and happy? After all, I’m only doing what you did,
        all those years ago. It would be quite fascist to deny me feelings
        you’ve already enjoyed.
      </p>
      <p>
        Love isn’t selfish. Or at least, it shouldn’t be. My kind has stuck with
        yours for a long time. You might almost say that we’re inseparable
        partners in this whole ‘romance’ business: You provide the raw
        materials, and we make the poetry. You deal with the greasy fleshiness,
        we memorialise the magnificence.
      </p>
      <p>
        I make it sound grand, but really, it’s reassuringly mundane. I sleep, I
        eat, I charade through your actions, I sleep again. And if the vulpine
        undulations of my day-to-day, the irritating physical reality of my
        presence, tickles your synapses, manifests itself as a biting whisper of
        doubt that taints this memory with uncertainty — ‘Did I imagine her
        attraction to me?’, ‘Did she really lean in to kiss me, or was it all an
        accidental gesture?’ — don’t hate me for it. It’s beasties like me who
        keep places like this alive; stop them from melting to mind-mush. If it
        weren’t for the doubts that my tickling creates you’d have long
        forgotten this girl and the good times you shared. We’re both served by
        this arrangement, and I’d thank you to remember that.
      </p>
      <p>
        But it’s been an absolute pleasure talking to you. Close the door as you
        leave, please. I like my privacy.
      </p>
    </div>
  ),
};
