import React from 'react';

export default {
  title: 'The Bookmaker’s Son'.toUpperCase(),
  author: 'Mark Edwards',
  bio: (
    <p>
      Mark Edwards is a writer from Calgary, Alberta. His journalistic work has
      appeared in numerous small newspapers and trade magazines, and this is his
      first published work of fiction. He has bad luck with watches.
    </p>
  ),
  story: (
    <div>
      <p>
        Father and son sat across from each other under the sickly, swaying
        light of a naked bulb overhead.
      </p>
      <p>
        The kitchen was stifling with the heat of the stove’s burners and the
        dull rush of dry central heating, humming against the quiet chill
        outside. A battered ‘Trucks of Yesteryear’ calendar hung on the
        wall—December’s picture was a 1950s peach truck—with December 25th, mere
        days away, circled in hopeful red marker.
      </p>
      <p>
        Jason stared at his plate. Beef, noodles, and watery sauce sloshed
        around in the wake of his fork. His father favoured food sold in cans or
        boxes and prepared quickly. He used to call it slop, smirking through
        his beard. ‘If your mother asks, we put vegetables in it, okay?’ He used
        to make it better, too.
      </p>
      <p>
        ‘So Dad, what kind of calendar should we get for next year? I mean it’s
        nearly the end of the year, right? I was thinking—’ The boy fell silent
        and poked at a noodle, sensing unsteadiness in his voice.
      </p>
      <p>
        ‘Good night, son,’ Angus said after he’d finished eating, his voice
        museum quiet. With a sigh, he pulled himself from his creaking chair and
        disappeared into the hallway.
      </p>
      <p>
        The boy cleared the table and washed the dishes, glancing a few times at
        the calendar. Christmas was always uncertain with his father, especially
        since Mom died. As he reached over to put away a plate, he felt his
        watch strap slither over his wrist, and heard the clear, unashamed crack
        as it hit the floor.
      </p>
      <p>
        Their house was small and narrow—an afterthought between two apartment
        buildings. The sound echoed down a hallway with a dark wood floor, only
        wide enough for two people to squeeze through sideways, and up a
        creaking flight of stairs to his father’s studio. Jason felt sick,
        praying he’d imagined it. Hoping his Dad hadn’t heard it.
      </p>
      <p>
        Abandoning the dishes, he scooped the shards and tiny cogs into a towel
        and ran upstairs to his room and sat on the edge of his bed, staring at
        the shattered watch.
      </p>
      <p className="divider">* * *</p>
      <p>
        Angus made pop-up books. He was among the best craftsmen who’d ever
        lived, though he’d never admit it, and one of the few who still crafted
        and bound the books by hand.
      </p>
      <p>
        He worked at a worn, pine-veneered bench in his bedroom. Within easy
        reach, he had his rulers, curves, angles, shears, paints and blades. He
        would paint each element on heavy card-stock, cut each piece by hand,
        and glue them together by the light of an old electric lamp. Page after
        page, he worked, often spending weeks on a single figure.
      </p>
      <p>
        He had made some money from his work. In fact, they were highly prized
        by wealthy collectors, and their infrequent contributions and purchases
        gave Angus enough money to work independently and support his son.
      </p>
      <p>
        The bookmaker liked his work. He told stories without words, preferring
        instead elaborate constructs of paper, glue, and paint. Manipulating the
        tabs and wheels in a certain order wove a narrative of colour and
        motion. Changing the order changed the story. He found the infinite
        permutations somehow comforting—if the story was unsatisfying, all the
        reader had to do was change the sequence of manipulations, and a new
        story came to the surface.
      </p>
      <p>
        He liked how one could open a page and, instantly, a world was created
        from nothingness.
      </p>
      <p>
        His current piece was a massive cathedral scene that stood three feet
        above the page, with a dozen independently manipulated people milling
        about the courtyard and windows. It promised to be more elaborate than
        the previous page, a fully articulated tiger with wet, pointed fangs.
      </p>
      <p>
        With a final dab of a hair-thin brush, Angus put the final detail into
        one of the cathedral’s stained-glass windows. He leaned back, rubbed
        tired eyes, and massaged his knuckles.
      </p>
      <p>
        Christmas used to depress him. He used to sit at the foot of his bed,
        nursing a splash of brandy in a coffee cup, thinking of his wife. She’d
        died in a car accident, or rather slipped and fell into traffic, so he’d
        heard. She loved wrapping presents. She loved the effort and attention
        the act required, so that the corners were crisp and the lines were
        even. She loved that her hard work would be torn apart as Jason hurried
        to see what the colourful paper concealed. Angus loved his wife.
      </p>
      <p>
        But, it got easier every year to remember her, and took less brandy to
        ease the pain. He had his work, and he had his son. Jason took good care
        of the house, and cleaned up after Angus cooked dinner.
      </p>
      <p>
        He felt a twinge of the regret one feels at remembering a long-forgotten
        appointment. It was too late now, Angus thought. His son was growing up
        fine on his own, and the two no longer had any common ground. Christmas
        was coming up soon—he hoped that his son would receive a card from a
        well-wishing relative. No time for shopping or presents.
      </p>
      <p>
        Angus considered walking downstairs and talking to his son, maybe asking
        him about school and giving him a little extra from his savings to buy a
        present.
      </p>
      <p>
        Suddenly, he noticed a flaw in one of the tiny people—a slender, dark
        haired teenager—and all else was forgotten as he reached for his brush.
        No time.
      </p>
      <p className="divider">* * *</p>
      <p>
        Jason wiped his eyes carefully. He was pretty sure it’d be bad for the
        watch if it got wet with salt water, but what else could be done? It was
        a gift from his father, five Christmases ago.
      </p>
      <p>
        He was nine years old, and his mother had died the previous year, on
        December 20th. He had saved up the change from his grocery money for
        weeks until he had enough to buy a packet of cookies and postage for his
        letter to Santa Claus. He’d gone out to cut a pine sapling, which he
        carefully stood in a bowl of water on the kitchen table and decorated as
        best he could.
      </p>
      <p>
        Christmas eve, Jason had carefully arranged the cookies on a plate,
        poured a generous glass of milk and went to bed. The next morning, he
        woke at sunrise and raced to the kitchen to find his father sitting at
        the table, nursing a cup of instant coffee, staring at the tree. His
        eyes were glistening and shadowy, as if he’d been crying.
      </p>
      <p>
        ‘Dad? Did Santa come?’ Jason had asked eagerly, staring with glee at the
        empty plate and glass.
      </p>
      <p>
        His father merely sighed, brushing crumbs from his moustache. Jason’s
        face fell.
      </p>
      <p>
        ‘Those were for Santa. He was supposed to…’ Jason’s voice trailed off as
        the awful realisation hit home. He wiped fierce, burning tears from his
        grey eyes.
      </p>
      <p>Angus looked at him, his face neutral, lip quivering slightly.</p>
      <p>
        ‘So there aren’t any presents,’ Jason said, voice shaking as he jabbed
        an accusing finger at the tree, which stood vacant and alone. ‘He
        forgot.’
      </p>
      <p>
        His father had risen to his feet and was fiddling with the clasp of his
        watch. He pressed it into the boy’s hand.
      </p>
      <p>
        ‘Merry Christmas, son,’ he had said. ‘It doesn’t tick. I think the
        battery is—’ He cleared his throat and nodded briskly. ‘Okay.’
      </p>
      <p>
        To the slow, regular rhythm of his father’s footsteps on the stairs,
        Jason had carefully placed the tiny sapling in the garbage and washed
        his father’s cup, the watch heavy and cold in his pocket. And now it lay
        smashed in a towel. He folded the mess and put the bundle in his
        backpack.
      </p>
      <p>
        He had saved almost fifty dollars in change from his weekly forays to
        the supermarket in a spaghetti sauce jar. His father coughed lightly
        behind his door. Maybe it would be just enough.
      </p>
      <p className="divider">* * *</p>
      <p>
        The kitchen air was heavy with the smell of dinner. Jason had set the
        table with a chicken, roast potatoes, and a bowl of carrots, peas, and
        asparagus. A pecan pie was browning in the oven, the first real food
        prepared in that kitchen in years. He hoped his father would appreciate
        it.
      </p>
      <p>
        At exactly five o’clock, exactly like every other day, Jason heard his
        father descend the stairs to cook dinner. Like any other day, Angus
        searched the cabinets for a saucepan and a box of pasta and sauce.
      </p>
      <p>‘Dad?’ Jason ventured.</p>
      <p>
        Angus turned slowly, frowning at this interruption of routine. ‘What is
        it, Jason?’
      </p>
      <p>
        ‘I cooked dinner,’ he replied. ‘It’s not slop but I think it looks
        pretty good.’
      </p>
      <p>
        His father looked at the table for a while before sitting at his
        customary place. Jason carefully carved some meat off the bird and
        placed it on the plate with the potatoes and vegetables. He poured his
        father a glass of water from the tap, letting the rusty effluvium run
        for a while before clear water poured forth from the ancient pipes.
      </p>
      <p>‘Butter?’ Jason asked.</p>
      <p>
        Angus looked at him questioningly, and the boy slid the yellow brick
        across the table. ‘For the vegetables, if you want.’
      </p>
      <p>
        Angus speared a carrot with his fork and took a tentative bite. He
        chewed for a long time before breathing deeply. ‘This is good,’ he said,
        leaning back in his chair.
      </p>
      <p>
        Jason felt like crying. ‘Thanks,’ he said, swallowing the lump in his
        throat.
      </p>
      <p>
        They ate in their customary silence, each lost in his own thoughts.
        Angus turned down a second plate with a raised hand, and Jason cleared
        the dishes.
      </p>
      <p>‘Good night—’ Angus began.</p>
      <p>
        ‘Dad, wait,’ Jason said desperately. ‘I, uh, I have a Christmas present
        for you.’
      </p>
      <p>
        Angus’ eyes widened and he turned his head to look at the calendar.
        Jason reached beneath his chair and produced a small package wrapped in
        newspaper. Angus held it for a long time before he carefully removed the
        tape and paper, and opened the small box.
      </p>
      <p>‘What is it?’</p>
      <p>
        ‘It’s your watch. You gave it to me six years ago at Christmas. I
        accidentally smashed it, but I had enough money to pay the watchmaker at
        the mall to fix it.’
      </p>
      <p>
        Angus took the watch out of the box. The face, flawless and clear,
        glittered in the light of the naked, swaying bulb. His jaw muscles
        twitched in time with the long-silent ticking as he held the watch to
        his ear.
      </p>
      <p>
        Without a word, the bookmaker walked upstairs. He returned minutes
        later, holding something behind his back.
      </p>
      <p>
        ‘I made this for you. I didn’t wrap it. I hope that’s okay,’ Angus said.
        His voice was ragged and tentative, as though he were struggling to
        remember how to form words. He held out a slender tome, bound in red
        leather with no title.
      </p>
      <p>
        Jason took it reverently and turned it over in his hands. ‘Dad, can we
        afford this?’
      </p>
      <p>‘I think we can. I think this is important.’</p>
      <p>
        The boy set the book carefully on the table and turned to the first
        page.
      </p>
      <p>
        A snowy mountain sprang forth. He played with the wheels and tabs and
        watched tiny figures ski down the mountain and ride the gondola to the
        top. Angus watched in rapture as his creation came to life. He saw his
        favourite tale, about a mendicant priest in Africa, play out in his
        son’s hands, and felt a well of pride. The boy had a good imagination.
        He wished he could look at the book with fresh eyes, like a child, and
        discover the mysteries anew. He saw his son smile at the juggling bear
        in the circus page, and remembered the hundreds of prototypes he had
        built to make the motion feel as organic as possible.
      </p>
      <p>
        ‘How do you like it so far?’ he asked, but the boy seemed to ignore him.
      </p>
      <p>
        Jason flipped to the final page, eager to see what mysteries it held. He
        heard the paper tear, seeming to echo through the house. A stray dab of
        glue had held several segments of the cathedral together, rending the
        massive building in half to expose the naked white paper. Shame spread
        warm and heavy across his face. He tried to press the page back into
        shape, to fix it some way, after all that had happened. Just to fix it
        all and make it the way it was.
      </p>
      <p>
        ‘Dad, I’m sorry,’ Jason said, looking at his father’s stricken face.
        Angus slowly lowered himself back into his chair, staring at the ruins
        of his cathedral. ‘Can we fix it?’ Jason asked, desperation making his
        voice shake.
      </p>
      <p>
        Angus shook his head slowly. ‘Is that pecan pie I smell?’ he asked after
        another long silence.
      </p>
      <p>
        It took a while for Jason to find some words. ‘Do you want some coffee
        with it?’
      </p>
      <p>‘No.’</p>
      <p>
        Jason took the pie out of the oven, still warm. He cut two slices and
        put them in bowls—they had no more clean plates.
      </p>
      <p>
        Angus took a bite and chewed slowly. He reached out and squeezed his
        son’s hand, and Jason squeezed back.
      </p>
    </div>
  ),
};
