import Chiles from '../content/chiles';
import Edwards from '../content/edwards';
import Ewing from '../content/ewing';
import Innis from '../content/innis';
import Kertesz from '../content/kertesz';
import Marshall from '../content/marshall';

interface Story {
  author: string;
  bio: React.ReactElement;
  story: React.ReactElement;
  title: string;
}

export default (path: string): Story => {
  switch (path) {
    case '/chiles':
      return Chiles;
    case '/edwards':
      return Edwards;
    case '/ewing':
      return Ewing;
    case '/innis':
      return Innis;
    case '/kertesz':
      return Kertesz;
    case '/marshall':
      return Marshall;
    default:
      return Chiles;
  }
};
